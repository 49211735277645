import React from 'react';
import axios from "axios";
import Helmet from 'react-helmet'

import Layout from '../components/layout'
// import Lightbox from 'react-images'
import Gallery from '../components/Gallery'
import Carousel, { Modal, ModalGateway } from "react-images";

import javatar from '../assets/images/joey-accardo.jpeg'
import thumb01 from '../assets/images/fulls/barry-sanders.jpeg'
import thumb02 from '../assets/images/fulls/bo-and-marcus.jpeg'
import thumb03 from '../assets/images/fulls/bo-jackson.jpeg'
import thumb04 from '../assets/images/fulls/david-ortiz.jpeg'
import thumb05 from '../assets/images/fulls/derrick-thomas.jpeg'
import thumb06 from '../assets/images/fulls/eric-davis.jpeg'
import thumb07 from '../assets/images/fulls/kobe-bryant.jpeg'
import thumb08 from '../assets/images/fulls/marshawn-lynch.jpeg'
import thumb09 from '../assets/images/fulls/tom-brady.jpeg'
import thumb10 from '../assets/images/fulls/walter-payton.jpeg'
import thumb11 from '../assets/images/fulls/jerry-rice.jpeg'

import full01 from '../assets/images/fulls/barry-sanders.jpeg'
import full02 from '../assets/images/fulls/bo-and-marcus.jpeg'
import full03 from '../assets/images/fulls/bo-jackson.jpeg'
import full04 from '../assets/images/fulls/david-ortiz.jpeg'
import full05 from '../assets/images/fulls/derrick-thomas.jpeg'
import full06 from '../assets/images/fulls/eric-davis.jpeg'
import full07 from '../assets/images/fulls/kobe-bryant.jpeg'
import full08 from '../assets/images/fulls/marshawn-lynch.jpeg'
import full09 from '../assets/images/fulls/tom-brady.jpeg'
import full10 from '../assets/images/fulls/walter-payton.jpeg'
import full11 from '../assets/images/fulls/jerry-rice.jpeg'

import hl01 from '../assets/images/marcus-autograph.jpg'
import hl02 from '../assets/images/mama-lynch.jpg'

const DEFAULT_IMAGES = [
    { id: '11', source: full11, thumbnail: thumb11, caption: 'Fuel the GOAT', description: 'Jerry Rice<br />Hand made; Materials - colored pencil, marker, and paper'},
    { id: '1', source: full01, thumbnail: thumb01, caption: 'Heart of a lion, eye of the tiger', description: 'Barry Sanders<br />Hand made; Materials - colored pencil, paint, paper'},
    { id: '2', source: full02, thumbnail: thumb02, caption: 'Picture Perfect Backfield', description: 'Bo Jackson & Marcus Allen<br />Hand made; Materials - colored pencil, paper<br />*Original 11x14 available'},
    { id: '3', source: full03, thumbnail: thumb03, caption: 'The Athlete', description: 'Bo Jackson<br />Hand made; Materials - colored pencil, mixed media, paper'},
    { id: '4', source: full04, thumbnail: thumb04, caption: 'Thankful', description: 'David "Big Papi" Ortiz<br />Hand made; Materials - colored pencil, mixed media, paper'},
    { id: '5', source: full05, thumbnail: thumb05, caption: 'DT', description: 'Derrick Thomas<br />Hand made; Materials - colored pencil, paper'},
    { id: '6', source: full06, thumbnail: thumb06, caption: 'Eric the Red', description: 'Eric Davis<br />Hand made; Materials - colored pencil, paper'},
    { id: '7', source: full07, thumbnail: thumb07, caption: 'Mamba Mentality', description: 'Kobe Bryant<br />Hand made; Materials - colored pencil, paper<br />*Original 16x20 available'},
    { id: '8', source: full08, thumbnail: thumb08, caption: 'Home Grown', description: 'Marshawn Lynch<br />Hand made; Materials - colored pencil, paint, paper'},
    { id: '9', source: full09, thumbnail: thumb09, caption: 'The Standard', description: 'Tom Brady<br />Hand made; Materials - colored pencil, marker, paint, paper'},
    { id: '10', source: full10, thumbnail: thumb10, caption: 'Sweetness', description: 'Walter Payton<br />Hand made; Materials - colored pencil, paper'}
];

const HIGHLIGHT_IMAGES = [
	{ id: 'HL01', source: hl01, thumbnail: hl01, caption: 'Hall of Famer Marcus Allen signing one of my prints', description: '' },
	{ id: 'HL02', source: hl02, thumbnail: hl02, caption: 'Mama Lynch enjoying one of my original pieces', description: '' }
];

class HomeIndex extends React.Component {
	render() {
        const siteTitle = "Joseph Accardo - Prismacolor Sports Artist";
		const siteDescription = "";

		this.state = {
			submitting: false
		};

		const onSubmitHandler = (e) => {
			e.preventDefault();
		
			// disable button because we're currently submitting
			this.setState(state => ({
				submitting: true
			}));
		
			// submit form to getform servers
			axios({
				method: "post",
				url: "https://getform.io/f/0860c25d-fbc3-4eb7-b164-7b203489d929",
				data: new FormData(e.target)
			}).then(res => {
				// display thank-you module
				this.setState(state => ({
					submitting: false
				}));
				console.log("successful response", res);
			}).catch(err => {
				// display error message
				this.setState(state => ({
					submitting: false
				}));
				console.log("error response", err);
			});
		};

		return (
            <Layout>
                <Helmet>
                        <title>{siteTitle}</title>
                        <meta name="description" content={siteDescription} />
                </Helmet>

                <div id="main">

                    <section id="one">
                        <header className="major">
                            <h2>Joseph Accardo</h2>
                        </header>
						
						<div className="row uniform 50%">
							<p className="6u 12u$(xsmall)">Los Angeles based sports artist. Originals and limited prints available on poster board signed by the artist. Available in multiple sizes. Great addition for any sports fan!! <a href="#three">Contact me for pricing and availability.</a></p>
							<div className="6u 12u$(xsmall)"><img src={javatar} className="image fit" /></div>
						</div>
                    </section>

                    <section id="two">
                        <h2>Gallery</h2>

                        <Gallery images={DEFAULT_IMAGES.map(({ id, source, thumbnail, caption, description }) => ({
                            source,
                            thumbnail,
                            caption,
                            description
                        }))} />

                        {/* <ul className="actions">
                            <li><a href="#" className="button">Full Portfolio</a></li>
                        </ul> */}
                    </section>

					<section id="highlights">
						<h2>Highlights</h2>

						<div>
							<h3>Beckett</h3>
							<p>
								Sports Artist Spotlight
								<br />
								<a href="https://www.beckett.com/news/sports-artist-spotlight-joseph-accardo/" target="_blank">https://www.beckett.com/news/sports-artist-spotlight-joseph-accardo</a>
							</p>
						</div>

						<div>
							<h3>Venice Paparazzi</h3>
							<p>
								Spotlight article featuring myself and work.
								<br />
								<a href="https://www.venicepaparazzi.com/2018/04/10/artist-joey-accardo/" target="_blank">https://www.venicepaparazzi.com/2018/04/10/artist-joey-accardo</a>
							</p>
						</div>

						<div>
							<h3>Fatpacks Podcast</h3>
							<p>
							Had the pleasure to talk about my work and sports with Beckett media’s Eric Norton on the Fatpacks podcast.
								<br />
								<a href="https://open.spotify.com/episode/4g2jRFYtwdFj0Yo9NblpsG?si=92ECwswlSdWhgMSac6QRuA" target="_blank">Listen on Spotify</a>
								<br />
								<a href="https://podcasts.apple.com/us/podcast/fat-packs-podcast/id847634315?i=1000462036417" target="_blank">Listen on Apple Podcasts</a>
							</p>
						</div>

						<Gallery images={HIGHLIGHT_IMAGES.map(({ id, source, thumbnail, caption, description }) => ({
                            source,
                            thumbnail,
                            caption,
                            description
                        }))} />
					</section>

                    <section id="three">
                        <h2>Contact Me</h2>
                        <p>Limited number of prints on poster board signed by the artist. Available in multiple sizes. Great addition for any sports fan!!</p>
                        <div className="row">
                            <div className="12u 12u$(small)">
                                <form onSubmit={onSubmitHandler}>
                                    <div className="row uniform 50%">
                                        <div className="6u 12u$(xsmall)"><input type="text" name="name" id="name" placeholder="Name" /></div>
                                        <div className="6u 12u$(xsmall)"><input type="email" name="email" id="email" placeholder="Email" /></div>
                                        <div className="12u"><textarea name="message" id="message" placeholder="Message" rows="4"></textarea></div>
                                    </div>

									<ul className="actions" style={{marginTop: 10}}>
										<li><input type="submit" value="Send Message" disabled={this.state.submitting} /></li>
									</ul>
                                </form>
                            </div>
                            {/* <div className="4u 12u$(small)">
                                <ul className="labeled-icons">
                                    <li>
                                        <h3 className="icon fa-home"><span className="label">Address</span></h3>
                                        1234 Somewhere Rd.<br />
                                        Nashville, TN 00000<br />
                                        United States
                                    </li>
                                    <li>
                                        <h3 className="icon fa-mobile"><span className="label">Phone</span></h3>
                                        000-000-0000
                                    </li>
                                    <li>
                                        <h3 className="icon fa-envelope-o"><span className="label">Email</span></h3>
                                        <a href="#">hello@untitled.tld</a>
                                    </li>
                                </ul>
                            </div> */}
                        </div>
                    </section>

                </div>

            </Layout>
        )
    }
}

export default HomeIndex